import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { ResolveStart, Router } from '@angular/router';
import { AuthService } from '@common/data-access/service/auth/auth.service';
import { NavigationTrackerService } from '@common/data-access/service/navigation-tracker/navigation-tracker.service';
import { StateService } from '@common/data-access/service/state/state.service';
import { TranslateService } from '@ngx-translate/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { catchError, throwError } from 'rxjs';

const getErrorTitle = (statusCode: number) => {
  switch (statusCode) {
    case 400: {
      return 'ERROR_MESSAGE.TITLE_400';
    }
    case 401: {
      return 'ERROR_MESSAGE.TITLE_401';
    }
    case 403: {
      return 'ERROR_MESSAGE.TITLE_403';
    }
    case 404: {
      return 'ERROR_MESSAGE.TITLE_404';
    }
    case 500: {
      return 'ERROR_MESSAGE.TITLE_500';
    }
    default: {
      return 'ERROR_MESSAGE.TITLE_403';
    }
  }
};

export const universalInterceptor: HttpInterceptorFn = (req, next) => {
  const authService = inject(AuthService);
  const stateService = inject(StateService);
  const translateService = inject(TranslateService);
  const router = inject(Router);
  const notification = inject(NzNotificationService);
  const navigationtTracker = inject(NavigationTrackerService);

  const token = authService.get();
  req = req.clone({
    setHeaders: {
      Authorization: `Bearer ${token}`,
    },
  });

  return next(req).pipe(
    catchError((err: HttpErrorResponse) => {
      if (err) {
        if (navigationtTracker.routerEvents.value instanceof ResolveStart) {
          switch (err.status) {
            case 403:
              router.navigate(['/error/403']);
              break;

            case 404:
              router.navigate(['/error/404']);
              break;

            case 500:
              router.navigate(['/error/500']);
              break;

            default:
              break;
          }
        } else {
          let errMsg: string = '';

          if (req.url.includes('api-loyalty')) {
            if (req.url.includes('voucher')) {
              errMsg = `ERROR_MESSAGE.${err.error.code}`;
            } else {
              errMsg = `ERROR_MESSAGE.${err.error.error}`;
            }
          } else if (req.url.includes('api-membership')) {
            errMsg = `ERROR_MESSAGE.${err.error.message}`;
          } else {
            errMsg = `ERROR_MESSAGE.${err.error.code}`;
          }
          notification.create(
            'error',
            translateService.instant(getErrorTitle(err.status)),
            `${translateService.instant(errMsg)}<br />
            <span class='error-code'>(Error ${err.status.toString()})</span>
            `
          );
        }
      }

      stateService.setLoadingPage(false);
      return throwError(() => err);
    })
  );
};
